<template>
  <Modal
    v-model="isShow"
    title="奖金提现申请"
    class-name="i-modal"
    width="700px"
  >
    <Form
      :model="obj"
      class="modelViewFrom"
      ref="form"
      label-position="left"
      :label-width="80"
      :rules="rules"
    >
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Icon" label="支付凭证">
            <i-upload
              :fileKey="UploadType.PayLogPic"
              @onChange="onChange"
              ref="UploadLogistic"
            ></i-upload>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="PayType" label="支付方式">
            <Select clearable v-model="obj.PayType" placeholder="支付方式">
              <Option
                v-for="(item, index) in PayTypeEnum"
                :key="index"
                :value="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="PaySerial" label="流水号">
            <Input type="text" v-model="obj.PaySerial"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="MemberCode" label="会员编号">
            <Input type="text" v-model="obj.MemberCode" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="DrawDate" label="申请时间">
            <Input type="text" v-model="obj.DrawDate" disabled></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Amount" label="奖金">
            <Input type="text" v-model="obj.Amount" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="StatusCN" label="状态">
            <Input type="text" v-model="obj.StatusCN" disabled></Input>
          </FormItem>
        </Col>
      </Row>
      <!-- <Row :gutter="16">
        <Col span="12">
        <FormItem prop="HandleAmount"
                  label="服务费">
          <Input type="text"
                 v-model="obj.HandleAmount"
                 disabled></Input>
        </FormItem>
        </Col>
        <Col span="12">
        <FormItem prop="RealAmount"
                  label="应支付金额">
          <Input type="text"
                 v-model="obj.RealAmount"
                 disabled></Input>
        </FormItem>
        </Col>
      </Row> -->
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="BankName" label="银行名称">
            <Input type="text" v-model="obj.BankName" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="BankCard" label="银行卡号">
            <Input type="text" v-model="obj.BankCard" disabled></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="isEdit || isAdd"
        type="primary"
        @click="handleSave"
        :loading="SaveLoading"
      >
        {{ !SaveLoading ? "保存" : "保存中..." }}
      </Button>
    </div>
  </Modal>
</template>
<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import IUpload from "@/components/upload/index.vue";
export default {
  name: "add-logistics-modal",
  data() {
    return {
      isShow: false,
      isEdit: false,
      isAdd: true,
      SaveLoading: false,
      loading: false,
      files: [],
      obj: {},
      rules: {
        PayType: [{ required: true, message: "支付方式不能为空" }],
        PaySerial: [{ required: true, message: "流水号不能为空" }],
      },
    };
  },
  methods: {
    onChange(opt) {
      this.files = opt.files;
    },
    async handleSave() {
      const _this = this;
      try {
        if (_this.files.length <= 0) {
          _this.$Message.warning("请上传支付凭证");
          return;
        }
        _this.$refs.form.validate(async (valid) => {
          if (valid) {
            _this.SaveLoading = true;
            const res = await _this.$refs.UploadLogistic.Upload();
            if (res.success.length !== 0) {
              _this.obj.PayPic = res.success[0];
              const response = await api.PayBonusDraw({
                id: this.obj.ID,
                PaySerial: this.obj.PaySerial,
                PayMoney: this.obj.Amount,
                PayType: this.obj.PayType,
                PayPic: this.obj.PayPic,
              });
              if (response.Status === 100) {
                _this.$Message.success("保存成功！");
                _this.SaveLoading = false;
                _this.isShow = false;
                _this.$emit("on-save", this.obj);
              } else {
                _this.SaveLoading = false;
                _this.isShow = false;
                _this.$Message.error(response.Msg);
              }
            } else {
              _this.$Message.error("上传凭证失败");
              this.SaveLoading = false;
            }
          }
        });
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.SaveLoading = false;
      }
    },
  },
  watch: {
    isShow: function (val) {
      if (val && this.obj.Logo) {
        let files = [
          {
            _key: this.obj.Logo,
            _file: this.$setImagePrefix(this.obj.Logo),
            success: true,
          },
        ];
        this.files = this.$refs.UploadLogistic.uploadFiles = files;
      } else {
        this.files = this.$refs.UploadLogistic.uploadFiles = [];
      }
    },
  },
  computed: {
    PayTypeEnum() {
      return bizEnum.PayTypeEnum;
    },
    UploadType() {
      return bizEnum.UploadType;
    },
  },
  components: { IUpload },
};
</script>
